import { Box } from '@mui/material'
import React from 'react'
import "./mob.css"

const Videoo = () => {
    return (
        <>
            <Box >
                <video className='mob' loop autoPlay muted controls style={{ border: 'none', width: '100%',   }} >
                    <source src="final aniamtion.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Box>

        </>
    )
}

export default Videoo