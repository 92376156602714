import React from 'react'

import { Box, Typography } from '@mui/material'
const Card = ({ title, pic }) => {
  return (
    <Box mt={2} px={5} pb={5} sx={{
      background: '#21497F',
      borderRadius: '15px'
    }}>
      <Box mt={1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
        <img src={`/${pic}.png`} style={{marginTop: '1em'}} alt={pic} width='150px' height='150px' />
      
      <Box mt={2} py={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant='p' sx={{
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '26px',
          color: '#FFF'
        }}>{title}</Typography>
      </Box>
      </Box>



    </Box>
  )
}

export default Card