import React from "react";
import Welcome from "../components/Student Welcome/Welcome";
import { Helmet } from 'react-helmet-async'
const StudentWelcome = () => {
  return (
    <>
      <Helmet>
        <title> Student Welcome | As-Siraat </title>
        <meta
          name="description"
          content="As-Siraat helps students  to take the assessment and find the right jobs for them" />
      </Helmet>
      <Welcome />;
    </>
  );
};

export default StudentWelcome;
