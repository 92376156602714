import React, { useState, useContext, useEffect } from 'react'
import { AppBar, Avatar, Box, Button, Toolbar, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import DrawerComp from './DrawerComp';

import { Link, useNavigate, } from 'react-router-dom'
import { WebContext } from '../../Context/ContextProvider';
import './navbar.css'
import axios from 'axios';

const Navbar = () => {
    const { user, setUser, setToken, setLoggedInAs } = useContext(WebContext);
    const [selected, setSelected] = useState('home');
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'))
    const PAGES = [
        { page: 'Home', id: 'home' },
        { page: 'Skill Assessment', id: 'skill' },
        { page: 'Get Started', id: 'get-started' },
        { page: 'About Us', id: 'about-us' },
    ]
    const handleLogout = async () => {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
        setUser()
        setToken()
        setLoggedInAs(null)
    }

    return (
        <React.Fragment>
            <AppBar elevation={10} sx={{
                backgroundColor: 'rgba(33,73,127,0.5)',
                paddingY: { xs: '0.09em', md: '0.5em' }
            }}>
                <Toolbar>

                    <Link to='/' >
                        <img className='logo' src='/As-siraat.png' width={80} alt='As-Siraat logo' />
                    </Link>
                    {
                        isMatch ? (<DrawerComp sx={{ marginLeft: 'auto' }} />) : (
                            <React.Fragment>
                                <Box sx={{
                                    marginLeft: 'auto',
                                    fontWeight: 600,
                                    fontSize: '1.1em',
                                    lineHeight: '34px'

                                }}>
                                    <Box sx={{ display: 'flex' }}>
                                        {
                                            PAGES.map((page) => (
                                                <Box onClick={() => setSelected(page.id)} key={page.id} sx={{ position: 'relative' }}>
                                                    <a style={{ fontWeight: 700, lineHeight: '3em', color: 'white', marginLeft: '1em', textDecoration: 'none', padding: '0.5em' }} href={`#${page.id}`}  >{page.page}</a>

                                                    <Box sx={{
                                                        visibility: selected === page.id ? 'visible' : 'hidden',
                                                        boxShadow: ' 0px 8px 16px rgba(0, 0, 0, 0.05)', backgroundColor: '#fbae18',
                                                        borderRadius: '50%',
                                                        width: '8px', height: '8px', position: 'absolute', top: '80%', left: '50%',
                                                    }}>

                                                    </Box>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                </Box>

                                {user?.email ?
                                    <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                                        <Tooltip title={user.name || user.companyName} arrow>
                                            <Avatar sx={{ bgcolor: '#fbae40', color: '#FFFF' }} alt='profilePic' src={user.profilePic} />
                                        </Tooltip>
                                        <Button onClick={handleLogout} sx={{
                                            marginLeft: '10px', backgroundColor: '#fbae18', fontWeight: 'bold', borderRadius: '15px', '&:hover': {
                                                transform: 'scaleX(1.05)',
                                                backgroundColor: '#fbae40'

                                            }
                                        }} variant='ghost' > Logout  </Button>

                                    </Box> : <Box sx={{
                                        marginLeft: 'auto'
                                    }}>
                                        <Button variant='ghost' sx={{ fontWeight: 'bold', background: 'none' }} > <Link to="/login" style={{ color: '#FFF', textDecoration: 'none' }} > Login </Link>  </Button>
                                        <Button sx={{
                                            marginLeft: '10px', backgroundColor: '#fbae18', fontWeight: 'bold', borderRadius: '15px', '&:hover': {
                                                transform: 'scaleX(1.05)',
                                                backgroundColor: '#fbae40'

                                            }
                                        }} variant='ghost' > <Link to={{
                                            pathname: '/signup',

                                        }} style={{ color: '#FFF', textDecoration: 'none' }} > Sign Up </Link>  </Button>
                                    </Box>}
                                
                            </React.Fragment>
                        )
                    }

                </Toolbar>


            </AppBar>
        </React.Fragment >
    )
}

export default Navbar