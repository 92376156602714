import { Box, TextField, Typography } from '@mui/material'
import React from 'react'

const GeneralInput = ({fieldName}) => {
    return (
        <Box>
            <Typography sx={{
                padding: '0.2em',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '21px',
                color: '#939191',
            }}> {fieldName} </Typography>
            <Box pr={2} sx={{ paddingLeft: '0.1em' }} >
                <TextField multiline size='small' fullWidth sx={{ borderRadius: '0.5em' }} ></TextField>
            </Box>
        </Box>
    )
}

export default GeneralInput