import React, { useState } from 'react'
import { Drawer, IconButton, Button,Tooltip,Avatar, List, Box } from '@mui/material'
import {Link} from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import { useContext } from 'react';
import { WebContext } from '../../Context/ContextProvider'; 
const DrawerComp = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const {user} = useContext(WebContext)
    const PAGES = [
        { page: 'Home', id: 'home' },
        { page: 'Skill Assessment', id: 'skill' },
        { page: 'Get Started', id: 'get-started' },
        { page: 'About Us', id: 'about-us' },
    ]
     const handleLogout = () => {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
        window.location.replace('http://localhost:3000/');
    }
    return (
        <React.Fragment>
            <Drawer open={openDrawer} anchor='left' onClose={() => setOpenDrawer(false)} >
                <List  sx={{ backgroundColor: '#21497F', height: '100%' }}>

                    
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {
                            PAGES.map((page) => (
                                <Box key={page.id} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', '&:hover': {
                                    transform: 'scale(1.05)'
                                }}}>
                                    <a style={{ fontWeight: 700, lineHeight: '3em', color: 'white', marginLeft: '1em', textDecoration: 'none', padding: '0.5em' }} href={`#${page.id}`} onClick={()=> setOpenDrawer(false)}  >{page.page}</a>

                                </Box>
                            ))
                        }
                    </Box>
                    
                     {user ?
                                    <Box sx={{ marginTop: 'auto', display: 'flex', alignItems: 'center' }}>
                                        <Tooltip title={user.name || user.companyName} arrow>
                                            <Avatar sx={{ bgcolor: '#fbae40', color: '#FFFF' }} alt='profilePic' src={user.profilePic} />
                                        </Tooltip>
                                        <Button onClick={handleLogout} sx={{
                                            marginLeft: '10px',color: 'white', backgroundColor: '#fbae18', fontWeight: 'bold', borderRadius: '15px', '&:hover': {
                                                transform: 'scaleX(1.05)',
                                                backgroundColor: '#fbae40'

                                            }
                                        }} variant='ghost' > Logout  </Button>

                                    </Box> : <Box sx={{
                                        marginTop: '8rem',
                                        justifyContent: 'center', 
                                        alignItems: 'center', 
                                        display: 'flex', 
                                         gap:'1em',
                                        flexDirection: 'column'
                                    }}>
                                        <Button variant='ghost' sx={{ fontWeight: 'bold', background: 'none' }} > <Link to="/login" style={{ color: '#FFF', textDecoration: 'none' }} > Login </Link>  </Button>
                                        <Button sx={{
                                            marginTop: '10px', backgroundColor: '#fbae18', fontWeight: 'bold', borderRadius: '15px', '&:hover': {
                                                transform: 'scaleX(1.05)',
                                                backgroundColor: '#fbae40'

                                            }
                                        }} variant='ghost' > <Link to="/signup" style={{ color: '#FFF', textDecoration: 'none' }} > Sign Up </Link>  </Button>
                                    </Box>}

                </List>
                
            </Drawer>
            <IconButton sx={{marginLeft: 'auto', marginRight: '1rem'}} onClick={()=>setOpenDrawer(!openDrawer)}>
                <MenuIcon sx={{ cursor: 'pointer', color: 'white' }} />
            </IconButton>
        </React.Fragment>


    )
}

export default DrawerComp