import React from "react";
import SignupForm from "../components/Signup/SignupForm";
import {Helmet} from 'react-helmet-async'
const SignUp = () => {
  return (
    <>
      <Helmet>
        <title>Sign Up | As-Siraat</title>
        <meta name="description" content="Sign up form of As-siraat company" />
      </Helmet>
      <SignupForm />
    </>
  );
};

export default SignUp;
