import React, { useContext, useEffect,useState } from 'react'
import Dashboard from '../components/Student Dashboard/Dashboard';
import {Link, useNavigate } from 'react-router-dom';
import { WebContext } from '../Context/ContextProvider';
import { Box, Typography } from '@mui/material';
const StudentDashboard = () => {
  const navigate= useNavigate();
  const [loading, setLoading] = useState(true);
  const {loggedInAs,token} = useContext(WebContext);
   useEffect(() => {
     if (!localStorage.getItem("token")) {
       navigate("/login");
     } else {
       if (token && loggedInAs) {
         if (loggedInAs === "Student") setLoading(false);
       }
     }
   }, [token, loggedInAs]);
  return (
    <>
      {loading ? null : <Dashboard />}
      { loggedInAs === 'Industry' ? <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',}} >
        <Typography> You are logged in as Industry. Please login as Student <Link to='/login'> login </Link> </Typography>
      </Box> : null }
    </>
  );
}

export default StudentDashboard