import { Box, Container, Typography } from '@mui/material'
import React from 'react'

import './Animation.css'
const Animation = () => {
  return (
    <Container sx={{ marginY: '5em',padding: '1em' }}>
      <Box sx={{display: 'flex', columnGap: '1em', alignItems: 'center', flexDirection:{xs: 'column', md: 'row'}, justifyContent: 'flex-start' }}>
        
        
        
        <Box sx={{ padding: '1.5em' }} >
          <video loop muted autoPlay style={{ objectFit: 'contain', borderRadius: '10px', width: '100%', height: 'auto' }} >
            <source  src="animation.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

        </Box>
        <Box id='text-wrapper' sx={{ width: { xs: '100%', md: '50%' } }}>
          <Typography className='order' sx={{
            padding: '0.3em',
            fontWeight: 600,
            fontSize: '50px',
            lineHeight: '85px',
            color: '#21497F'
          }}>
            Four Steps towards Better Future.
          </Typography>
        </Box>
      </Box>
    </Container>

  )
}

export default Animation