import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import ForwardIcon from '@mui/icons-material/Forward';
import { Link, useNavigate } from 'react-router-dom';
import { WebContext } from '../../Context/ContextProvider';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'
const Welcome = () => {
    const navigate = useNavigate();
    const {setUser,setToken,loggedInAs,setLoggedInAs,token} = useContext(WebContext);
    const handler = () => {
        if (!token) {
            navigate('/login')
        }
        else {
            if(loggedInAs === 'Industry'){
                window.location.href = "https://forms.gle/XEK1duz668VAcz3s5";
            }
            else{
                toast.error('You must be an employer to access it', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                return;
            }
        }
    }
    const handleLogout = () => {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
        setUser()
        setToken()
        setLoggedInAs(null)
        navigate('/');
    }
    return (
        <>
            <Link to='/' >
                <ForwardIcon sx={{
                    position: { lg: 'fixed', xs: 'absolute' },
                    top: { xs: '0%', md: '3%' },
                    left: { xs: '0%', md: '3%' },
                    color: '#fff',
                    fontSize: { xs: '2em', md: '3em' },
                    cursor: 'pointer',
                    transform: 'rotate(180deg)'
                }} />
            </Link>
            {token ? <Button onClick={handleLogout} sx={{
                backgroundColor: '#fbae18', color: 'white', position: 'absolute', right: 10, top: { xs: 2, md: 10 }, fontWeight: 'bold', borderRadius: '15px', '&:hover': {
                    transform: 'scaleX(1.05)',

                    backgroundColor: '#fbae40'

                }
            }} variant='ghost' > Logout  </Button> : null}

            <Box py={3} sx={{ display: 'flex', backgroundColor: '#21497F', justifyContent: 'center', alignItems: 'center', height: { md: '100vh' }, }}>
                <Box sx={{ marginTop: { xs: '1em', md: '0' }, width: { md: '70%', xs: '100%' }, boxShadow: '10px 20px 100px rgba(0,0,0,0.5)', marginX: { xs: '0.5em', md: '0' }, backgroundColor: '#FFF', borderRadius: '20px' }}>
                    <Typography p={1} variant='h4' sx={{
                        fontWeight: 700,
                        fontSize: '33.75px',
                        lineHeight: '51px',
                        color: '#21497F',
                        textAlign: 'center'
                    }} >Welcome to As-Siraat  </Typography>
                    <Typography p={1} my={1} variant='h6' sx={{
                        fontWeight: 600,
                        fontSize: '1.5em',
                        lineHeight: '36px',
                        color: '#21497F',
                        textAlign: 'center'
                    }} >For Industry </Typography>

                    <Grid container >
                        <Grid my={1} item xs={12}>
                            <Typography sx={{
                                textAlign: 'center', fontWeight: 600,
                                fontSize: '1.2em',
                                lineHeight: '30px',
                                color: '#21497F',
                            }}> Find the best talent for your full-time, part-time, contract, internship, or freelance jobs in any location. </Typography>
                            <Box sx={{ textAlign: 'center' }}>
                                <img width='260' height='auto' src='/industry.png' alt='tech-test' />
                            </Box>
                            
                        </Grid>

                  
                    </Grid>
                    <Box my={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                        <Button onClick={handler} sx={{
                            marginTop: '0.74em', backgroundColor: '#fbae18', borderRadius: '1em', color: 'white',
                            '&:hover': {
                                backgroundColor: '#fbae18',
                                transform: 'scaleX(1.05)'
                            }
                        }} variant='ghost' > Start Hiring <ForwardIcon /> </Button>

                    </Box>
                </Box>

            </Box>

            <ToastContainer toastStyle={{
                backgroundColor: "#21497F", color: 'white', boxShadow: '0 0 15px rgba(0,0,0,0.5)', fontFamily: [
                    "Poppins",
                    "Roboto",
                    "Helvetica Neue",
                    "Arial",
                    "sans-serif",
                ].join(","),
            }} />
        </>
    )
}

export default Welcome