import axios from "axios";
import { createContext, useEffect, useState } from "react";

//creating the chat context

export const WebContext = createContext();

export const WebContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [loggedInAs, setLoggedInAs ] = useState(null);
 
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const token = localStorage.getItem("token");
    setUser(userInfo);
    setToken(token);
    
    if(userInfo !== null){
    if(!userInfo?.name){
      setLoggedInAs('Industry')
    }
    if (!userInfo?.companyName) {
      setLoggedInAs("Student");
    }
  }
  }, []);

  return (
    <WebContext.Provider value={{ user, token,loggedInAs,setLoggedInAs,setUser,setToken }}>
      {children}
    </WebContext.Provider>
  );
};
