import { Box, Typography, Button } from '@mui/material'
import ForwardIcon from '@mui/icons-material/Forward';
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

const StudentIndustryCard = ({ designation, title, description, studentOrIndustry, imgName, buttonText }) => {
  
    return (
        <Box sx={{
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
            borderRadius: '10px',
            padding: '10px',
        }}>
            <Typography sx={{
                textAlign: 'center', fontWeight: 600,
                fontSize: '26px',
                lineHeight: '39px',
                marginBottom: '0.5em',
                color: '#21497F'
            }} > {`Are you ${designation}?`}</Typography>
            <Box sx={{
                display: 'flex',
                height: '250px',
                alignItems: 'center',
            }}>
                <Box p={1}>
                    <Typography variant='h2' sx={{
                        fontWeight: 500,
                        fontSize: '19px',
                        lineHeight: '30px',
                        marginBottom: '0.5em',
                        color: '#21497F'
                    }}>{title}</Typography>
                    <Typography variant='p' sx={{
                        color: '#2F69B6',
                        fontSize: '17px',
                        lineHeight: '28px',
                        
                    }} >
                        {description}
                    </Typography>
                    <Box>
                        <Button endIcon={<ForwardIcon />} sx={{
                            marginTop: '0.5em',
                            backgroundColor: '#fbae18', borderRadius: '1em', color: 'white',
                            '&:hover': {
                                backgroundColor: '#fbae18',
                                transform: 'scaleX(1.05)'
                            }
                        }} variant='ghost' > <Link style={{
                            textDecoration: 'none',
                            color: 'inherit'
                        }} to={`/${studentOrIndustry}/welcome`}>{buttonText} </Link></Button>
                    </Box>
                </Box>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <img src={`/${imgName}.png`} style={{ objectFit: 'contain' }} height='100%' width='100%' alt={studentOrIndustry} />
                </Box>
            </Box>
        </Box>

    )
}

export default StudentIndustryCard