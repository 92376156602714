import { Box, Typography } from '@mui/material'
import React from 'react'

const Infocard = ({ background,borderColor,number, text, imgName}) => {
    return (
        <Box sx={{
            width: "160px",
            height: '200px',
            background: `${background}`,
            border: `3px solid ${borderColor}`,
            boxShadow: `0px 8px 16px ${background}`,
            borderRadius: '15px',
            padding: '0.5em',
            textAlign: 'center',
            
        }}>
            <img src={`${imgName}.png`} alt={imgName} />
            <Typography variant='h4' sx={{
                fontWeight: 700,
                fontSize: '22px',
                lineHeight: '33px',
                color: '#703e78',
                
            }} >{number}  </Typography>
            <Typography variant='h4' sx={{
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '24px',
                color: '#703e78',
                
            }} >{text} </Typography>
        </Box>
    )
}

export default Infocard