import { Box,  Typography } from '@mui/material'
import React from 'react'
import Infocard from './Infocard'
const Infographics = () => {
    return (
        <>
            <Box sx={{ marginTop: '1em',display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{
                    width: '80%',
                    border: '1px solid #2F69B6',
                    boxShadow: '4px 8px 16px rgba(33, 73, 127, 0.1)',
                    borderRadius: { xs: '30px', md: '60px' },
                    background: '#EBECF0',
                    padding: '0.5em',
                    marginY: '8px'
                }} >
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <Typography sx={{
                            fontWeight: 600,
                            padding: '0.3em',
                            fontSize: {xs: '16px', sm:'18px', md: '20px'},
                            lineHeight: '25px',
                            color: '#21497F',
                            marginY: '0.3em'

                        }}>Do you know the current situation of skilled workforce gap of Pakistan's IT & ITES industry?</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <Box sx={{paddingTop: '0.5em', height: '90%', display: 'flex', justifyContent: {xs: 'center', md: 'flex-start'}, flexWrap: 'wrap', alignItems: 'center' }} >
                            <Box sx={{padding: {xs: '0.5em', md: '0em'}, marginX: '0.5em', marginY: '0.3em' }}>
                                <Infocard background='rgba(112, 62, 120, 0.05)' borderColor='#703E78' number='600000+' text='IT professionals*' imgName='professionsal' />
                            </Box>
                            <Box sx={{padding: {xs: '0.5em', md: '0em'}, marginX: '0.5em', marginY: '0.3em' }}>
                                <Infocard background='rgba(119, 196, 209, 0.1)' borderColor='#245D6B' number='25,000+' text='IT graduates/year' imgName='graduates' />
                            </Box>
                            <Box sx={{padding: {xs: '0.5em', md: '0em'}, marginX: '0.5em', marginY: '0.3em' }}>
                                <Infocard background='rgba(218, 229, 255, 0.5)' borderColor='#558AFF' number='24000' text='Unemployed IT graduates' imgName='job-hunt' />
                            </Box>
                            <Box sx={{padding: {xs: '0.5em', md: '0em'}, marginX: '0.5em', marginY: '0.3em' }}>
                                <Infocard background='rgba(190, 238, 203, 0.15)' borderColor='#76B889' number='19451' text='Skill Gap of 42 technology tracks' imgName='skill' />
                            </Box>

                        </Box>
                    </Box>
                    <Typography p={1} variant='h4' sx={{
                        fontWeight: 700,
                        fontSize: '10px',
                        lineHeight: '30px',
                        color: '#21497F',


                    }} >***Source PASHA skill IT requirement report 2021 </Typography>
                </Box>
            </Box>
        </>
    )
}

export default Infographics