import React from "react";
import IndustryWelcome from '../components/Industry Welcome/IndustryWelcome'
import {Helmet} from 'react-helmet-async';
const StudentWelcome = () => {
  return (
    <>
      <Helmet>
        <title> Industry Welcome | As-Siraat </title>
        <meta name='description' content="As-Siraat helps industry find the skillful candidates for their work"/>
      </Helmet>
      <IndustryWelcome />
    </>
  );
};

export default StudentWelcome;
