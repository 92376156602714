import { Box, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
    const PAGES = [
        { page: 'Home', id: 'home' },
        { page: 'Skill Assessment', id: 'skill' },
        { page: 'Get Started', id: 'get-started' },
        { page: 'About Us', id: 'about-us' },
    ]
    return (
        <Box mx={0} sx={{ marginTop: { xs: '1rem', sm: '-4rem' }, display: 'flex', alignItems: 'center', flexWrap: 'wrap', height: '400px', background: '#21497F', borderTopLeftRadius: { xs: '22.5px', sm: '45px' }, borderTopRightRadius: { xs: '22.5px', sm: '45px' } }}>
            <Box sx={{ flex: { xs: '100%', md: '30%' } }} >
                <Box>
                    <Typography my={2} sx={{
                        fontWeight: 600,
                        color: 'white',
                        paddingLeft: '2em',
                        fontSize: '22.5px',
                        lineHeight: '34px',
                    }} > As-Siraat </Typography>
                    <Typography sx={{
                        fontStyle: 'normal', fontWeight: '300',
                        fontSize: '12px',
                        lineHeight: '18px',
                        paddingLeft: '2.55em',
                        color: 'white'
                    }}>Knowledge is power if applied </Typography>
                </Box>
            </Box>
            <Box sx={{ width: '80%',flex: { xs: '100%', md: '70%' }, alignItems: 'center' }} >
                <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, justifyContent: 'center', alignItems: 'center'}} >
                {

                        PAGES.map((page) => <Box key={page.id} sx={{ margin: { xs: '4px', md: '8px' } }}> 
                            <a style={{ fontWeight: 700, lineHeight: '3em', color: 'white', marginLeft: '1em', textDecoration: 'none', padding: '0.5em' }} href={`#${page.id}`}  >{page.page}</a>

                        </Box>)

                }
                </Box>
            </Box>

        </Box>
    )
}

export default Footer