import { Box, Typography } from '@mui/material';
import React,{useContext, useEffect,useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import IndustryDashboard from '../components/Industry Dashboard/IndustryDashboard'
import { WebContext } from '../Context/ContextProvider';
const IndustryDash = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { loggedInAs, token } = useContext(WebContext);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }else{
      if (token && loggedInAs) {
       if(loggedInAs === 'Industry') 
       setLoading(false); 
    }
  }}, [token,loggedInAs]);
  return (
    <>
      {loading ? null : <IndustryDashboard />}
      {loggedInAs === "Student" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Typography>
            {" "}
            You are logged in as Student. Please login as Student{" "}
            <Link to="/login"> login </Link>{" "}
          </Typography>
        </Box>
      ) : null}
    </>
  );
}

export default IndustryDash