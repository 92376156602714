import React from 'react'
import UploadIcon from '@mui/icons-material/Upload';
import { Box, Button, Grid, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import ForwardIcon from '@mui/icons-material/Forward';
import { Link, useNavigate} from 'react-router-dom';
import ResumeModal from './ResumeModal';
const Resume = () => {
    const navigate = useNavigate();
    return (
        <>
            <Link to='/' >
                <ForwardIcon sx={{
                    position: { lg: 'fixed', xs: 'absolute' },
                    top: { xs: '0%', md: '3%' },
                    left: { xs: '0%', md: '3%' },
                    color: '#fff',
                    fontSize: { xs: '2em', md: '3em' },
                    cursor: 'pointer',
                    transform: 'rotate(180deg)'
                    
                }} />
            </Link>

            <Box py={3} sx={{ display: 'flex', backgroundColor: '#21497F', justifyContent: 'center', alignItems: 'center', height: { md: '100vh' }, }}>
                <Box sx={{ marginTop: { xs: '1em', md: '0' }, width: { md: '70%', xs: '100%' }, boxShadow: '10px 20px 100px rgba(0,0,0,0.5)', marginX: { xs: '0.5em', md: '0' }, backgroundColor: '#FFF', borderRadius: '20px' }}>
                    <Typography p={1} variant='h4' sx={{
                        fontWeight: 700,
                        fontSize: '33.75px',
                        lineHeight: '51px',
                        color: '#21497F',
                        textAlign: 'center'
                    }} >Just One Step Away! </Typography>
                    <Typography p={1} my={1} variant='h6' sx={{
                        fontWeight: 600,
                        fontSize: '1.5em',
                        lineHeight: '36px',
                        color: '#21497F',
                        textAlign: 'center'
                    }} >Choose One  </Typography>

                    <Grid container >
                        <Grid my={1} item xs={12} md={6}>
                            <Typography sx={{
                                textAlign: 'center', fontWeight: 600,
                                fontSize: '1.2em',
                                lineHeight: '30px',
                                color: '#21497F',
                            }}> Upload CV </Typography>
                            <Box sx={{ textAlign: 'center' }}>
                                <img width='260' height='250' src='/uploadCV.png' alt='upload CV' />
                            </Box>
                            <Box my={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                                <ResumeModal>
                                    <Button sx={{
                                        marginTop: '0.74em', backgroundColor: '#fbae18', borderRadius: '1em', color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#fbae18',
                                            transform: 'scaleX(1.05)'
                                        }
                                    }} variant='ghost' > Upload Resume <UploadIcon /> </Button>
                                </ResumeModal>
                            </Box>
                        </Grid>

                        <Grid my={1} item xs={12} md={6}>
                            <Typography sx={{
                                marginTop: { xs: '2em', md: '0' },
                                textAlign: 'center', fontWeight: 600,
                                fontSize: '1.2em',
                                lineHeight: '30px',
                                color: '#21497F',
                            }}> Edit profile manually </Typography>
                            <Box sx={{ textAlign: 'center' }}>
                                <img width='248' src='/editProfile.png' alt='disc-test' />
                            </Box>
                            <Box my={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                                    <Button onClick={()=> navigate('/student/dashboard')} sx={{
                                        marginTop: '0.74em', backgroundColor: '#fbae18', borderRadius: '1em', color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#fbae18',
                                            transform: 'scaleX(1.05)'
                                        }
                                    }} variant='ghost' > Edit Profile <EditIcon />  </Button>
                                    {/* when clicked on edit profile, where should go? */}
                           
                            </Box>
                        </Grid>
                    </Grid>

                </Box>

            </Box>
        </>
    )
}

export default Resume