import { Box, Button, Checkbox, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import UploadIcon from '@mui/icons-material/Upload';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { useContext } from 'react'
import { WebContext } from '../../Context/ContextProvider'
import GeneralInput from './GeneralInput';

const Profile = () => {
    const [userAvailable, setUserAvailable] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const { user } = useContext(WebContext)
    useEffect(() => {
        if (user) {
            setUserAvailable(true)
        }

    }, [user]);
    if (userAvailable) {
        return (

            <Grid container columnGap={2} columns={16} >
                <Grid item xs={16} md={5}>
                    <Box pb={1} sx={{
                        background: '#FFFFFF',
                        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.05)',
                        borderRadius: '10px'
                    }} >
                        <Typography sx={{
                            paddingX: '0.2em',
                            fontWeight: 500,
                            fontSize: '20px',
                            lineHeight: '35px',
                            color: '#21497F',
                        }} > User Profile  </Typography>
                        <Box sx={{ paddingBottom: '0.3em', display: 'flex', gap: '1em' }}>
                            <Box px={2}>
                                <img width='110px' height='110px' style={{ borderRadius: '50%' }} src={user.profilePic} alt='user profile' />
                            </Box>
                            <Box>
                                <Button variant='contained' sx={{
                                    borderRadius: '10px', padding: '0.5em', backgroundColor: '#E29600', color: 'white', marginY: '0.8em', '&:hover': {
                                        backgroundColor: '#E29600',
                                        opacity: 0.95
                                    }
                                }}> Change Picture </Button>
                                <Button sx={{ borderRadius: '10px', padding: '0.5em', backgroundColor: '#fff', border: '1px solid #888787', color: '#888787', marginY: '0.5em' }}> View Picture </Button>
                            </Box>
                        </Box>
                        <Typography sx={{
                            padding: '0.2em',
                            fontWeight: 500,
                            fontSize: '20px',
                            lineHeight: '30px',
                            color: '#21497F',
                        }} > {user.name} </Typography>

                        <Typography my={1} variant='h6' sx={{
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '10px',
                            color: 'rgba(69, 69, 69, 0.6)',

                        }} >Upload Resume  </Typography>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingBottom: '0.2em'
                        }}>
                            <Box sx={{
                                padding: '0.3em',
                            
                                background: '#F1F2F5',
                                border: '1px dashed #9F9F9F',
                                borderRadius: '8px',
                                width: '80%',
                                textAlign: 'center',

                            }} >
                                <img src='/upload.png' width='15px' height='18px' alt='upload' />
                                <Typography sx={{ fontSize: '12px', color: '#8F8F8' }} >Click to upload resume or CV here </Typography>
                                <Typography sx={{ fontSize: '12px', color: '#21497f' }} >PDF, DOCX, .doc upto 1MB</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                    <Button sx={{
                                        marginTop: '0.1em', backgroundColor: '#FFF', border: '1px solid rgba(60, 60, 60, 0.8)', borderRadius: '5px', color: 'rgba(60, 60, 60, 0.8)',
                                        '&:hover': {
                                            backgroundColor: '#FFF',
                                            transform: 'scaleX(1.05)'
                                        }
                                    }} variant='ghost' > <UploadIcon /> Upload File </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <GeneralInput fieldName='Full Name' />
                            <GeneralInput fieldName='Email' />
                            <GeneralInput fieldName='Mobile' />
                            <GeneralInput fieldName='LinkedIn Profile Link' />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={16} md={5}>
                    <Box pb={1} sx={{
                        background: '#FFFFFF',
                        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.05)',
                        borderRadius: '10px'
                    }}>
                        <Typography sx={{
                            padding: '0.2em',
                            fontWeight: 500,
                            fontSize: '20px',
                            lineHeight: '30px',
                            color: '#21497F',
                        }}>Education</Typography>
                        <Box px={2}>
                            <GeneralInput fieldName='What is name of your institution?' />
                            <GeneralInput fieldName='What is name of your Degree?' />
                            <GeneralInput fieldName='What is name of your Department?' />
                        </Box>
                        <Box pl={2} pr={4} mt={1} >
                            <Typography sx={{
                                padding: '0.2em',
                                fontWeight: 600,
                                fontSize: '14px',
                                lineHeight: '21px',
                                color: '#939191',
                            }}>
                                Start Date
                            </Typography>

                            <DatePicker 
                            selected={startDate}
                            onChange={(e)=> setStartDate(e)}
                            
                             />
                        </Box>
                        <Box pl={2} pr={4} mt={1} >
                            <Typography sx={{
                                padding: '0.2em',
                                fontWeight: 600,
                                fontSize: '14px',
                                lineHeight: '21px',
                                color: '#939191',
                            }}>
                                End Date
                            </Typography>

                            <DatePicker
                                selected={startDate}
                                onChange={(e) => setStartDate(e)}
                                showYearDropdown
                                scrollableYearDropdown
                            />
                        </Box>
                        <Box p={1} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                            <Checkbox /> 
                            <Typography sx={{
                                padding: '0.2em',
                                fontWeight: 600,
                                fontSize: '14px',
                                lineHeight: '21px',
                                color: '#939191',
                            }}>
                                Currently Studying
                            </Typography>
                        </Box>
                        <Box p={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Button variant='contained' sx={{
                                background: 'rgba(92, 92, 92, 0.55)',
                                borderRadius: '10px',
                                padding: '0.5em',
                                color: 'white',
                                '&:hover': {
                                    background: 'rgba(92, 92, 92, 0.45)',
                                }
                            }}>
                                Add Education
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={16} md={5}>
                    <Box pb={1} sx={{
                        background: '#FFFFFF',
                        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.05)',
                        borderRadius: '10px'
                    }}>
                        <Typography sx={{
                            padding: '0.2em',
                            fontWeight: 500,
                            fontSize: '20px',
                            lineHeight: '30px',
                            color: '#21497F',
                        }}>Job Experience</Typography>
                        <Box px={2}>
                            <GeneralInput fieldName='Title of Your Job' />
                            <GeneralInput fieldName='Company Name' />
                            <GeneralInput fieldName='Company Link' />
                            <GeneralInput fieldName='Description' />

                        </Box>
                        <Box pl={2} pr={4} mt={1} >
                            <Typography sx={{
                                padding: '0.2em',
                                fontWeight: 600,
                                fontSize: '14px',
                                lineHeight: '21px',
                                color: '#939191',
                            }}>
                                Start Date
                            </Typography>

                            <DatePicker
                                selected={startDate}
                                onChange={(e) => setStartDate(e)}

                            />
                        </Box>
                        <Box pl={2} pr={4} mt={1} >
                            <Typography sx={{
                                padding: '0.2em',
                                fontWeight: 600,
                                fontSize: '14px',
                                lineHeight: '21px',
                                color: '#939191',
                            }}>
                                End Date
                            </Typography>

                            <DatePicker
                                selected={startDate}
                                onChange={(e) => setStartDate(e)}
                                showYearDropdown
                                scrollableYearDropdown
                            />
                        </Box>
                        <Box p={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Checkbox />
                            <Typography sx={{
                                padding: '0.2em',
                                fontWeight: 600,
                                fontSize: '14px',
                                lineHeight: '21px',
                                color: '#939191',
                            }}>
                                Currently working
                            </Typography>
                        </Box>
                        <Box p={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Button variant='contained' sx={{
                                background: 'rgba(92, 92, 92, 0.55)',
                                borderRadius: '10px',
                                padding: '0.5em',
                                color: 'white',
                                '&:hover': {
                                    background: 'rgba(92, 92, 92, 0.45)',
                                }
                            }}>
                                Add Experience
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid >
        )
    } else {
        return null //prefer to use loader which I will implement later
    }
}

export default Profile