import React from 'react'
import Uphiring from '../components/Home/Uphiring'
import CardSlider from '../components/Home/CardSlider'
import AssiraatImage from '../components/Home/AssiraatImage'
import Infographics from '../components/Home/Infographics'
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Home/Navbar'
import Animation from '../components/Home/Animation'
import Footer from '../components/Home/Footer'
import WhatIs from '../components/Home/WhatIs'
import GetStarted from '../components/Home/GetStarted'
import Videoo from '../components/Home/Videoo'
const Home = () => {
  return (
    <>
      <Helmet>
        <title>As-Siraat</title>
        <meta name='description' content="As-Siraat aims to bridge the gap between industry and academia through a digital platform that will help fresh graduate students to improve their employability skills and ultimately
         cover the skilled IT workforce shortage for Pakistan's IT industry."/>
      </Helmet>
      <Navbar/>
      {/* <AssiraatImage /> */}
      <Videoo />
      <Uphiring />
      <CardSlider />
      <Infographics />
      <GetStarted/>
      <Animation/>
      <WhatIs/>
      <Footer/>
    </>
  );
}

export default Home