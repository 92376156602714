import React from 'react'
import { Box, Typography } from '@mui/material'
import MailIcon from '@mui/icons-material/Mail';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
const WhatIs = () => {
    return (
        <div id='about-us' style={{marginTop: '1rem',display: 'flex', justifyContent: 'center', alignItems: 'center'}} name='About Us' >
        <Box sx={{
            width: { md: '80%' },
            border: '1px solid #2F69B6',
            color: '#FFF',
            background: '#2F69B6',
            boxShadow: '4px 8px 16px rgba(33, 73, 127, 1)',
            borderRadius: {xs: '22.5px', sm: '45px', md: '60px'},
        }} >
            <Box my={1} sx={{ display: 'flex', marginX: { xs: '1em', md: '3em' }, flexDirection: 'column', alignItems: 'center' }} >
                <Typography sx={{
                    fontWeight: 600,
                    fontSize: '22.5px',
                    lineHeight: '34px',
                }} mt={2}> What is As-Siraat? </Typography>
                <Typography mt={1} sx={{
                    fontWeight: 300,
                    fontSize: {xs: '14px', sm: '15px', md: '16px'},
                    lineHeight: '150%',
                }} >
                    As-Siraat aims to bridge the gap between industry and academia through a digital platform to enhance the employability skills of fresh IT graduate and undergraduate students (youth aged 15-29 comprises 60% of the population of country) to cover the skilled workforce gap of Pakistan IT & ITES industry so that they become the optimal resource of the industry.
                </Typography>
                <Typography sx={{
                    fontWeight: 600,
                    fontSize: '22.5px',
                    lineHeight: '34px',
                }} mt={2}> Get in Touch with Us  </Typography>
                <Box mt={2} sx={{ display: 'flex', alignItems: 'center', }} >
                    <Typography mx={2}>  <a target='_blank' style={{ textDecoration: 'none', color: 'inherit' }} rel="noreferrer" href='mailto:support@as-siraat.com'><MailIcon fontSize='32px' />    support@as-siraat.com</a> </Typography>
                </Box>

                <Typography sx={{
                    fontWeight: 600,
                    fontSize: '22.5px',
                    lineHeight: '34px',
                }} mt={2}> Follow Us on  </Typography>
                <Box mt={1}>
                    {/* links should be in the anchor tag or link tag, will be implemented later */}
                    <Box>

                        <a target='_blank' rel='noreferrer' style={{textDecoration: 'none', color: 'inherit', marginLeft: '4px'}} href=' https://www.facebook.com/profile.php?id=100076416650895'><FacebookIcon /></a>
                        <a target='_blank' rel='noreferrer' style={{ textDecoration: 'none', color: 'inherit', marginLeft: '4px' }} href='  https://www.linkedin.com/company/as-siraat-ai/'><LinkedInIcon /></a>
                        <a target='_blank' rel='noreferrer' style={{ textDecoration: 'none', color: 'inherit', marginLeft: '4px' }} href=' https://www.instagram.com/as_siraat_/?hl=en'><InstagramIcon /></a>
                    </Box>
                </Box>
            </Box>

        </Box>
        </div>
    )
}

export default WhatIs