import React from 'react'
import { Box, Grid } from '@mui/material'

import StudentIndustryCard from './StudentIndustryCard';
const Uphiring = () => {
    return (
        <Box id='skill'>
            <Grid gap={3} container justifyContent='center' alignItems='center' my={4} sx={{
                width: '100%',
                backgroundColor: 'white',

            }} >
                <Grid my={4} item xs={12} sm={12} md={5}>
                    <StudentIndustryCard designation='Student' description='Do you possess the ideal combination of technical and soft skills for the job?' title='Assess your employability' buttonText='Test your ability' imgName='pic1' studentOrIndustry='student'/>
                </Grid>
                <Grid item my={4} xs={12} sm={12} md={5}>
                    <StudentIndustryCard designation='Industry' description='Here at As-Siraat we provide the best resources to aid you in your projects.' buttonText='find resources' title='Looking for recruiting?' imgName='pic2' studentOrIndustry='industry'/>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Uphiring