import React from 'react'
import { Helmet } from 'react-helmet-async'
import SideBar from './SideBar'
const IndustryDashboard = () => {
  return (
    <>
      <Helmet>
        <title> Industry Dashboard | As-Siraat </title>
        <meta name='description' content="Dashboard for Industry on As-Siraat platform" />
      </Helmet>
      <SideBar />
      
    </>
  )
}

export default IndustryDashboard