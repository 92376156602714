import { Box, Typography } from '@mui/material'
import React from 'react'

const ComingSoon = () => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%'}} >

        <Typography sx={{
              fontWeight: 600, color: '#21497f', fontSize: {xs: '60px', md: '85px'}, lineHeight: '60px', transform: 'rotate(-45deg)',
        }}>

            Coming Soon...
        </Typography>
    </Box>
  )
}

export default ComingSoon