import { Box, Button, Grid, Typography } from '@mui/material'
import ForwardIcon from '@mui/icons-material/Forward';
import { useNavigate } from 'react-router-dom';
import { WebContext } from '../../Context/ContextProvider';
import React, { useContext } from 'react'

const Dash = () => {
    const navigate = useNavigate();
    const handler = () => {
        if (!token) {
            navigate('/login')
        }
        else {
            window.location.href = "https://forms.gle/XEK1duz668VAcz3s5";
        }
    }
    const { token } = useContext(WebContext);
    return (
        <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={12} md={7}>
                <Box pt={7} sx={{ flex: '50%' }}>
                    <Box sx={{backgroundColor: '#FFF', borderRadius: '20px' }}>
                        <Typography p={1} variant='h4' sx={{
                            fontWeight: 700,
                            fontSize: '33.75px',
                            lineHeight: '51px',
                            color: '#21497F',
                            textAlign: 'center'
                        }} >Welcome Back  </Typography>


                        <Typography sx={{
                            textAlign: 'center', fontWeight: 600,
                            fontSize: '1em',
                            lineHeight: '30px',
                            color: '#21497F',
                        }}> Thank you for using our platform.We are looking for the best match candidates for you.We will inform you very soon.Stay tuned! </Typography>
                        <Box sx={{ textAlign: 'center' }}>
                            <img width='260' height='auto' src='/industry.png' alt='tech-test' />
                        </Box>
                        <Box my={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                            <Button onClick={handler} sx={{
                                marginTop: '0.74em', backgroundColor: '#fbae18', borderRadius: '1em', color: 'white',
                                '&:hover': {
                                    backgroundColor: '#fbae18',
                                    transform: 'scaleX(1.05)'
                                }
                            }} variant='ghost' > Start Hiring <ForwardIcon /> </Button>

                        </Box>
                    </Box>

                </Box>
            </Grid>
            <Grid item xs={12} md={5}>
                <Box sx={{
                    marginLeft: {md: '8rem', xs: '2rem'},
                    marginTop: '5rem',
                    boxShadow: 'inset 0px 8px 24px rgba(0, 0, 0, 0.05)',
                    borderRadius: '15px', backgroundColor: '#EBECF0', height: '305px', width: '200px'
                }} >
                    <Typography sx={{
                        padding: '0.4em',
                        fontWeight: 600,
                        fontSize: '17px',
                        lineHeight: '26px',
                        color: '#21497f',

                    }} >Updates</Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Dash