import React from 'react'
import {Helmet} from 'react-helmet-async';
import LoginForm from '../components/Login/LoginForm'
const Login = () => {
  return (
    <>
      <Helmet>
        <title>Login | As-Siraat</title>
        <meta name="description" content="Login  form of As-siraat" />
      </Helmet>
      <LoginForm />
    </>
  );
}

export default Login