import React,{useState,useContext} from 'react'
import { Drawer, List, Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import RouteIcon from '@mui/icons-material/Route';
import img from '../../asset/student pic.png'
import Profile from './Profile'
import ComingSoon from './ComingSoon';
import QuizIcon from '@mui/icons-material/Quiz';
import MobileSidebar from './MobileSidebar';
import { useNavigate } from 'react-router-dom';
import {WebContext} from '../../Context/ContextProvider';

const SideBar = () => {
    const navigate= useNavigate();
    const {setToken,setUser,setLoggedInAs} = useContext(WebContext);
    const [selected, setSelected] = useState('Profile');
    const Icons = [
        { title: 'Home', icon: <HomeIcon sx={{ fontSize: '32px', color: 'white' }} /> },
        { title: 'Learning Path', icon: <RouteIcon sx={{ fontSize: '32px', color: 'white' }} /> },
        { title: 'Give Test', icon: <QuizIcon sx={{ fontSize: '32px', color: 'white' }} /> },
        { title: 'Profile', icon: <AccountCircleIcon sx={{ fontSize: '32px', color: 'white' }} /> }]
    const footerIcons = [
        { title: 'Settings', icon: <SettingsIcon sx={{ fontSize: '32px', color: 'white' }} /> },
        { title: 'Logout', icon: <LogoutIcon sx={{ fontSize: '32px', color: 'white', transform: 'rotate(180deg)' }} /> },

    ]
    const handleFunction = (title) => {
        if (title === 'Logout') {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            setUser()
            setToken()
            setLoggedInAs(null)
            navigate('/');
            return
        }
        if (title === 'Settings') {
            setSelected(title)
            return
        }
        if (title === 'Profile') {
            setSelected(title)
            return
        }
        if (title === 'Learning Path') {
            setSelected(title)
            return
        }
        if (title === 'Home') {
            setSelected(title)
            return
        }
        if (title === 'Give Test') {
            setSelected(title)
            return
        }
    }
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <React.Fragment>
            <Box>
                
               {!isMatch ? <Drawer anchor='left' variant='permanent' >
                  
                    <List sx={{ boxShadow:  'inset 0px 4px 16px rgba(0, 0, 0, 0.05)',backgroundColor: '#21497F', height: '100%', }}>
                        <Box sx={{ padding: '0.5em', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Box sx={{ borderRadius: '5px', textAlign: 'center', backgroundColor: 'white', width: '60%', }}>
                                <img src={img} alt='student' width='85px' height='14px' />
                            </Box>
                        </Box>
                        <Box sx={{ paddingX: '2em', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
                            {
                                Icons.map((icon) => (
                                    <Box onClick={() => handleFunction(icon.title)} key={icon.title} sx={{
                                        padding: '0.2em', position: 'relative',
                                        
                                        paddingY: '0.5em', display: 'flex', width: '100%', marginTop: '1em', justifyContent: 'flex-start', alignItems: 'center', '&:hover': {
                                            transform: 'scale(1.05)', cursor: 'pointer', 
                                        }
                                    }} >
                                        <Box sx={{
                                            visibility: selected === icon.title ? 'visible' : 'hidden',
                                            boxShadow: ' 0px 8px 16px rgba(0, 0, 0, 0.05)', backgroundColor: '#fbae18',
                                            borderRadius: '0 5px 5px 0',
                                            width: '10px', height: '40px', position: 'absolute', top: '0.3em', left: '-1.7em',
                                        }}>
                                        </Box>
                                        <Box>
                                            {icon.icon}
                                        </Box>
                                        <Typography sx={{
                                            marginX: '1em',
                                            fontWeight: 600, color: 'white', fontSize: '14px', lineHeight: '22.5px',
                                        }}  > {icon.title} </Typography>

                                    </Box>
                                ))
                            }
                        </Box>
                        
                        

                    </List>

                    <Box sx={{ background: '#21497F', marginTop: 'auto' }}>
                        <hr style={{
                            width: '80%',
                            border: 0,
                            height: '2px',
                            background: '#fff'
                        }} />
                        <Box sx={{ display: 'flex', alignItems: 'center', paddingX: '2em', paddingBottom: '1em', justifyContent: 'center', flexDirection: 'column' }} >
                            {
                                footerIcons.map((icon) => (
                                    <Box onClick={() => handleFunction(icon.title)} key={icon.title} sx={{
                                        paddingY: '0.5em', display: 'flex', width: '100%', marginTop: '2em', justifyContent: 'center', alignItems: 'center', '&:hover': {
                                            transform: 'scale(1.05)', cursor: 'pointer'
                                        }
                                    }} >
                                        <Box>
                                            {icon.icon}
                                        </Box>
                                        <Typography sx={{
                                            marginX: '0.5em',
                                            fontWeight: 600, color: 'white', fontSize: '14px', lineHeight: '22.5px',
                                        }}  > {icon.title}  </Typography>

                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                </Drawer> : <MobileSidebar Icons={Icons} footerIcons={footerIcons} handleFunction={handleFunction} selected={selected} /> }

                <Box sx={{paddingLeft: {xs: '4.5em', md: '235px'}}}>
                    {
                        selected === 'Profile' ? <Profile /> : null 
                    }
                    {
                        selected === 'Give Test' ? <ComingSoon/>  : null 
                    }
                    {
                        selected === 'Home' ? <ComingSoon/>  : null 
                    }
                    {
                        selected === 'Learning Path' ? <ComingSoon/>  : null 
                    }
                    {
                        selected === 'Settings' ? <ComingSoon/>  : null 
                    }
                   
                    
                </Box>
                
            </Box>
        </React.Fragment>


    )
}

export default SideBar