import React, { useState, useEffect, useContext } from 'react'
import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import ForwardIcon from '@mui/icons-material/Forward'
import AppleIcon from '@mui/icons-material/Apple';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import validator from 'validator'
import { ToastContainer, toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { WebContext } from '../../Context/ContextProvider';

const LoginForm = () => {
    const navigate = useNavigate();
    const {setLoggedInAs,setToken,setUser,user} = useContext(WebContext);
    useEffect(() => {
        if (user?.email) {
            if (!user.companyName) {
                navigate('/student/welcome')
            } else {
                navigate('/industry/welcome')
            }
        }

    }, [user]);

    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    const handleSubmit = async () => {
        setLoading(true)
        if (!email || !password) {
            //toast here
            toast.error('Please Enter all fields', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setLoading(false)
            return;
        }
        if (!validator.isEmail(email)) {
            toast.error('Provide Valid Email Address', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setLoading(false)
            return;
        }
        try {
            const config = {
                headers: {
                    'Content-type': "application/json"
                }
            }
            
            const { data } = await axios.post('https://us-central1-as-siraat-b2345.cloudfunctions.net/app/api/user/login', JSON.stringify({ email, password }), config);
            localStorage.setItem('userInfo', JSON.stringify(data.user))
            localStorage.setItem('token', data.token)
            setUser(data.user);
            setToken(data.token);
            if (!data.user.companyName) {
                setLoggedInAs('Student');
                setLoading(false)
                navigate('/student/welcome')
            } else {
                setLoggedInAs('Industry');
                setLoading(false)
                navigate('/industry/welcome');
                
            }
       } catch (error) {
            toast.error(error.response.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setLoading(false)
        }

    }
    return (
        <>
            <Link to='/' >
                <ForwardIcon sx={{
                    position: { lg: 'fixed', xs: 'absolute' },
                    top: { xs: '0%', md: '3%' },
                    left: { xs: '0%', md: '3%' },
                    color: '#fff',
                    fontSize: { xs: '2em', md: '3em' },
                    cursor: 'pointer',
                    transform: 'rotate(180deg)'
                }} />
            </Link>
            <Box sx={{ display: 'flex', backgroundColor: '#21497F', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Box sx={{ width: { md: '45%', xs: '100%' }, boxShadow: '10px 20px 100px rgba(0,0,0,0.5)', marginX: { xs: '0.5em', md: '0' }, backgroundColor: '#FFF', borderRadius: '20px' }} >

                    <Grid container >
                        <Grid item xs={12} >
                            <Box mx={2} mt={2} >
                                <TextField onChange={(e) => setEmail(e.target.value)} sx={{ marginX: '0.5em', marginY: '0.5em', width: '100%', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)' }} variant='outlined' label='Email'  > </TextField>
                                <TextField onChange={(e) => setPassword(e.target.value)} type='password' sx={{ marginX: '0.5em', marginY: '0.5em', width: '100%', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)' }} variant='outlined' label='Password'  > </TextField>
                                <Button endIcon={ loading ? null : <ForwardIcon />} onClick={handleSubmit}  sx={{
                                    background: '#FFFFFF',
                                    padding: '1em',
                                    width: '100%',
                                    border: '1px solid #2F69B6',
                                    borderRadius: '15px',
                                    transition: 'all 200ms ease-in',
                                    '&:hover': {
                                        background: '#21497F',
                                        color: '#FFF',
                                    }
                                }} >{ loading ? <CircularProgress  /> : 'Login' } </Button>
                                <Box my={2}>
                                    <Typography sx={{
                                        fontSize: '16px',
                                        lineHeight: '32px',
                                        color: '#21497F',
                                        textAlign: 'center'
                                    }} > Not registered? <Link to='/signup' style={{ background: '#21497F', color: 'white', padding: '10px', borderRadius: '10px', textDecoration: 'none', }} > Sign Up </Link> </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }} >
                                    <Typography sx={{
                                        fontWeight: 600,
                                        fontSize: '17px',
                                        lineHeight: '30px',
                                        color: '#21497F'
                                    }} >or Login using</Typography>
                                </Box>
                                <br />

                                <Box my={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex' }} >
                                        <Box sx={{ marginX: '2px', width: '50px', height: '50px', borderRadius: '15px', border: '1px solid #9A9797' }}>
                                            <FacebookIcon  sx={{ fontSize: '50px', color: '#3b5998', borderRadius: '100%' }} />
                                        </Box>
                                        <Box sx={{ marginX: '2px', width: '50px', height: '50px', borderRadius: '15px', border: '1px solid #9A9797' }}>
                                            <LinkedInIcon sx={{ fontSize: '50px', color: '#0E76A8', borderRadius: '100%' }} />
                                        </Box>
                                        <Box  sx={{ padding: '1px', marginX: '2px', width: '50px', height: '50px', borderRadius: '15px', border: '1px solid #9A9797' }}>
                                            <img src='/google.png' alt='google' width='47px' height='47px' />
                                        </Box>
                                        <Box sx={{ marginX: '2px', width: '50px', height: '50px', borderRadius: '15px', border: '1px solid #9A9797' }}>
                                            <AppleIcon sx={{ fontSize: '50px', borderRadius: '100%' }} />
                                        </Box>
                                       
                                    </Box>
                                </Box>
                            </Box>


                        </Grid>
                    </Grid>
                </Box>
                {/* <ToastContainer /> */}
                <ToastContainer toastStyle={{
                    backgroundColor: "#21497F", color: 'white', boxShadow: '0 0 15px rgba(0,0,0,0.5)', fontFamily: [
                        "Poppins",
                        "Roboto",
                        "Helvetica Neue",
                        "Arial",
                        "sans-serif",
                    ].join(","), }} />
            </Box>
        </>
    )
}

export default LoginForm