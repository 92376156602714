import { createTheme } from "@mui/material";
export const theme = createTheme({
   typography: {
     fontFamily: [
       "Poppins",
       "Roboto",
       "Helvetica Neue",
       "Arial",
       "sans-serif",
     ].join(","),
   },
 });