import React from 'react'
import { Scrollbar, A11y } from 'swiper';
import Cards from './Card'
import { Swiper, SwiperSlide } from 'swiper/react';
import './cardSlider.css'

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/scrollbar';
import { Box, Typography } from '@mui/material';

const CardSlider = () => {
    return (
        <Box id='careers'>
            <Box sx={{ marginX: '0.5em',padding: '1em', backgroundColor: '#EBECF0', borderRadius: '0.99em' }}>
                <Box>
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: '24px',
                        lineHeight: '36px',
                        color: '#21497F'
                    }}> In demand skills now a days! </Typography>
                </Box>
                <Box sx={{ px: '1em' }}>
                    <Swiper
                        // install Swiper modules
                        modules={[Scrollbar, A11y]}
                        breakpoints={{

                            300: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 50,
                            },
                        }}
                        scrollbar={{ draggable: true, }}

                    >
                        <SwiperSlide><Cards title='Web Development' pic='web' /></SwiperSlide>
                        <SwiperSlide><Cards title='Mobile Development' pic='appdevelopment' /></SwiperSlide>
                        <SwiperSlide><Cards title='Artificial Intelligence' pic='ai' /></SwiperSlide>
                        <SwiperSlide><Cards title='Game Development' pic='game' /></SwiperSlide>

                        .
                    </Swiper>
                </Box>
            </Box>
        </Box>
    )
}

export default CardSlider