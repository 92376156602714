import Home from "./Pages/Home";
import { Route, Routes } from "react-router-dom";
import SignUp from "./Pages/SignUp";
import Login from "./Pages/Login";
import StudentWelcome from "./Pages/StudentWelcome";
import IndustryWelcome from './Pages/IndustryWelcome'
import UploadResume from "./Pages/UploadResume";
import './App.css'
import StudentDashboard from "./Pages/StudentDashboard";
import IndustryDash from "./Pages/IndustryDash";
function App() {
  
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/student/welcome" element={<StudentWelcome />} />
      <Route path="/industry/welcome" element={<IndustryWelcome />} />
      <Route path="/student/upload-resume" element={<UploadResume />} />
      <Route path="/student/dashboard" element={<StudentDashboard />} />
      <Route path="/industry/dashboard" element={<IndustryDash />} />
    </Routes>
  );
}

export default App;
