import React from 'react'
import SideBar from './SideBar'
import { Helmet } from 'react-helmet-async'
const Dashboard = () => {
  return (
    <>
    <Helmet>
        <title> Student Dashboard | As-Siraat </title>
        <meta name='description' content="Dashboard for student on As-Siraat platform"/>
    </Helmet>
    <SideBar/>
    </>
  )
}

export default Dashboard