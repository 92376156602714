import {initializeApp} from 'firebase/app';
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyAdKRjrKDa48QzHqYho_2fVp3eHAe6K45s",
  authDomain: "as-siraat-b2345.firebaseapp.com",
  projectId: "as-siraat-b2345",
  storageBucket: "as-siraat-b2345.appspot.com",
  messagingSenderId: "676063234797",
  appId: "1:676063234797:web:1e0cdc526514e5b5eddae4",
  measurementId: "G-K6YD1L33B1",
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);