import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload';
import React, { useState } from 'react'
import { storage } from '../../firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const ResumeModal = ({ children }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);
    const fileHandler = (file) => {
        setLoading(true);
        if (!file) {
            toast.error('Please select a file', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            }); //should be using toast
            setLoading(false);
            return; 
        }
        setLoading(true);
        uploadFile(file);
    }

    const uploadFile = (file) => {
        const extension = file.name.split('.')[file.name.split('.').length - 1];

        if (!(extension === 'pdf') &&  !(extension === 'docx') && !(extension ==='doc') ){
            toast.error('pdf,docx,doc type should be selected', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setLoading(false);
            return;
        }
        if(file.size > 3 * 1024 * 1024){
            toast.error('File size should be under 3MB', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setLoading(false);
            return;
        }
        const storageRef = ref(storage, `/Resumes/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file)
        uploadTask.on('state_changed', (snapshot) => {
            const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            
        }, (err) => {
            console.log(err)
        }, () => {
            //as the user will be loggedin in our case, so user is set... we will make the 
            //api in our backend that takes the id of the user and store the download url in string format
            //in the user model
            getDownloadURL(uploadTask.snapshot.ref).then(url => console.log(url));
            alert("Successfully uploaded")

            setLoading(false);
            setOpen(false);
            navigate('/student/welcome');
           
        })
    }
    return (
        <>
            <Typography onClick={() => setOpen(true)}>{children}</Typography>
            <Dialog onClose={() => setOpen(false)} fullWidth open={open} aria-labelledby='dialog-title' aria-describedby='dialog-content'>
                <DialogTitle id='dialog-title'>
                    <Box sx={{ marginTop: { xs: '1em', md: '0' }, width: '100%', backgroundColor: '#FFF', borderRadius: '20px' }}>

                        <Typography p={1} my={1} variant='h6' sx={{
                            fontWeight: 600,
                            fontSize: '1.5em',
                            lineHeight: '36px',
                            color: '#21497F',
                            textAlign: 'center'
                        }} >Upload Resume  </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{
                        padding: '0.3em',
                        background: '#F1F2F5',
                        border: '1px dashed #9F9F9F',
                        borderRadius: '8px',
                        width: '100%',
                        textAlign: 'center'
                    }} >
                        <img src='/upload.png' alt='upload' />
                        <Typography >Click to upload resume or CV here </Typography>
                        <Typography >PDF, DOCX, .doc upto 3MB</Typography>
                        
                        <Box sx={{
                            display: 'flex', gap: '1em', justifyContent: 'center', alignItems: 'center'
                        }}>
                        <label htmlFor="resume" style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                            marginTop: '0.74em', cursor: 'pointer', padding: '0.5em 1em', backgroundColor: '#FFF', border: '1px solid rgba(60, 60, 60, 0.8)', borderRadius: '5px', color: 'black',
                        }} > <UploadIcon /> Upload File
                        </label>
                        <input id="resume" type="file" onChange={(e) => {

                            setFile(e.target.files[0])
                            console.log(e.target.files[0]);
                        }
                        }  style={{display: 'none'}} />
                            {
                                file ? <span style={{textDecoration: 'underline'}}  >{file.name} </span> : null
                            }
                        </Box>
                    </Box>
                    
                    <Box onClick={() =>fileHandler(file) } my={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                        <Button sx={{
                            marginTop: '0.74em', backgroundColor: '#fbae18', borderRadius: '1em', color: 'white',
                            '&:hover': {
                                backgroundColor: '#fbae18',
                                transform: 'scaleX(1.05)'
                            }
                        }} variant='ghost' > { loading ? <CircularProgress sx={{color: 'white'}} /> :   'Submit Resume'} <UploadIcon /> </Button>

                    </Box>
                   
                    <DialogActions>
                        <Button variant='ghost' onClick={() => setOpen(false)}  > Close </Button>
                    </DialogActions>
                </DialogContent>

            </Dialog>
            <ToastContainer toastStyle={{
                backgroundColor: "#21497F", color: 'white', boxShadow: '0 0 15px rgba(0,0,0,0.5)', fontFamily: [
                    "Poppins",
                    "Roboto",
                    "Helvetica Neue",
                    "Arial",
                    "sans-serif",
                ].join(","),
            }} />
            
        </>
    )
}

export default ResumeModal