import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import ForwardIcon from '@mui/icons-material/Forward';
import { Link, useNavigate } from 'react-router-dom';
import { WebContext } from '../../Context/ContextProvider';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'
const Welcome = () => {
    const navigate = useNavigate();
    const {setUser,setToken,token,loggedInAs,setLoggedInAs} = useContext(WebContext);
    const handler = () => {
        if(!token){
            navigate('/login')
        }
        else{
             if(loggedInAs === 'Student'){
                 window.location.href = "https://forms.gle/yo1z4kRF8ZacnSyH6";
            }
            else{
                toast.error('You must be a Student to access it', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                return;
            }
             
        }
    }
    const handleLogout = () => {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
        setUser()
        setToken()
        setLoggedInAs(null)
        navigate('/');
    }
    return (
        <>
            <Link to='/' >
                <ForwardIcon sx={{
                    position: {lg: 'fixed', xs: 'absolute' },
                    top: {xs: '0%', md: '3%'},
                    left: { xs: '0%', md: '3%' },
                    color: '#fff',
                    fontSize: {xs: '2em', md: '3em'},
                    cursor: 'pointer',
                    transform: 'rotate(180deg)'
                }} />
            </Link>
           {token ?  <Button onClick={handleLogout} sx={{
                backgroundColor: '#fbae18', color: 'white', position: 'absolute', right: 10, top: { xs: 2, md: 10 }, fontWeight: 'bold', borderRadius: '15px', '&:hover': {
                    transform: 'scaleX(1.05)',

                    backgroundColor: '#fbae40'

                }
            }} variant='ghost' > Logout  </Button> : null}

            <Box py={3} sx={{ display: 'flex', backgroundColor: '#21497F', justifyContent: 'center', alignItems: 'center', height: {md: '100vh'}, }}>
                <Box  sx={{ marginTop: {xs: '1em', md: '0'} ,width: { md: '70%', xs: '100%' }, boxShadow: '10px 20px 100px rgba(0,0,0,0.5)', marginX: { xs: '0.5em', md: '0' }, backgroundColor: '#FFF', borderRadius: '20px' }}>
                    <Typography p={1} variant='h4' sx={{
                        fontWeight: 700,
                        fontSize: '33.75px',
                        lineHeight: '51px',
                        color: '#21497F',
                        textAlign: 'center'
                    }} >Welcome to As-Siraat  </Typography>
                    <Typography p={1} my={1} variant='h6' sx={{
                        fontWeight: 600,
                        fontSize: '1.5em',
                        lineHeight: '36px',
                        color: '#21497F',
                        textAlign: 'center'
                    }} >Test has two parts  </Typography>

                    <Grid container >
                        <Grid my={1} item xs={12} md={6}>
                            <Typography sx={{
                                textAlign: 'center', fontWeight: 600,
                                fontSize: '1.2em',
                                lineHeight: '30px',
                                color: '#21497F',
                            }}> Technical Test </Typography>
                            <Box sx={{textAlign:'center' }}>
                                <img width='250' height='250' src='/tech.png' alt='tech-test' />
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}} >
                                <Typography sx={{
                                    textAlign: 'left',
                                    fontWeight: 500,
                                    fontSize: '1em',
                                    lineHeight: '23px',
                                    color: 'rgba(125, 122, 122, 0.8)',

                                    width: { md: '50%', xs: '100%' }
                                }}> This test consist of 15 questions. Questions are specific to your area of expertise</Typography>

                            </Box>
                        </Grid>
                        
                        <Grid my={1}  item xs={12} md={6}>
                            <Typography sx={{
                                textAlign: 'center', fontWeight: 600,
                                fontSize: '1.2em',
                                lineHeight: '30px',
                                color: '#21497F',
                            }}> Disc Test </Typography>
                            <Box sx={{ textAlign: 'center' }}>
                                <img width='250' height='250' src='/disc.png' alt='disc-test' />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <Typography sx={{
                                    textAlign: 'left',
                                    fontWeight: 500,
                                    fontSize: '1em',
                                    lineHeight: '23px',
                                    color: 'rgba(125, 122, 122, 0.8)',

                                    width: { md: '50%', xs: '100%' }
                                }}> DISC test is behavioural test. It helps to measure personality and work style.</Typography>

                            </Box>
                        </Grid>
                    </Grid>
                    <Box my={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                        <Button onClick={handler} sx={{
                            marginTop: '0.74em', backgroundColor: '#fbae18', borderRadius: '1em', color: 'white',
                            '&:hover': {
                                backgroundColor: '#fbae18',
                                transform: 'scaleX(1.05)'
                            }
                        }} variant='ghost' > Test Yourself Here <ForwardIcon /> </Button>

                    </Box>
                </Box>

            </Box>
            <ToastContainer toastStyle={{
                backgroundColor: "#21497F", color: 'white', boxShadow: '0 0 15px rgba(0,0,0,0.5)', fontFamily: [
                    "Poppins",
                    "Roboto",
                    "Helvetica Neue",
                    "Arial",
                    "sans-serif",
                ].join(","),
            }} />
        </>
    )
}

export default Welcome