import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React,{useState} from 'react'
import ForwardIcon from '@mui/icons-material/Forward'
import AppleIcon from '@mui/icons-material/Apple';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link} from 'react-router-dom'
import validator from 'validator';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { useContext } from 'react';
import { WebContext } from '../../Context/ContextProvider';
const GetStarted = () => {
    const [selected, setSelected] = useState('Student');
    const [companyName, setCompanyName] = useState('');
    const {user} = useContext(WebContext);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const handleSubmit = async () => {
        if(selected === 'Student'){
        if (!name || !email || !password) {
            toast.error('Please enter all fields', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        }
    }
     if(selected === 'Industry'){
        if (!companyName || !email || !password) {
            toast.error('Please enter all fields', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        }
    }
        if (!validator.isEmail(email)) {
            toast.error('Provide Valid Email Address', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        }
        try {
            const config = {
                headers: {
                    'Content-type': "application/json"
                }
            }
            const { data } = await axios.post('http://127.0.0.1:4000/api/user/signup', JSON.stringify({ name, email, password,companyName }), config);
            localStorage.setItem('userInfo', JSON.stringify(data.user))
            localStorage.setItem('token', data.token)
            window.location.replace(selected === 'Student' ? 'http://localhost:3000/student/dashboard' : 'http://localhost:3000/industry/dashboard')



        } catch (error) {
            console.log(error)
            toast.error(error.response.data.error, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }
    return (
        <div id='get-started' style={{ marginBottom: '2rem',marginTop: '-6rem', backgroundColor: '#21497F', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',padding: '1em' }} >
            <Box sx={{ marginTop: '10em',display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1em'}} >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: {xs: 'column', md: 'row'} }}>
                    <Box sx={{flex: '50%',padding: '1em'}}>
                        <Typography sx={{
                            fontWeight: 600,
                            fontSize: {xs: '30px', sm: '40px', md: '55px'},
                            lineHeight: '55px',
                            color: 'white'
                        }} > Get Started with As-Siraat</Typography>
                        <Typography sx={{
                            paddingTop: '1em',
                            fontWeight: 300,
                            fontSize: '18px',
                            lineHeight: '30px',
                            color: 'white'
                        }} > At As-Siraat we bridge the gap between industry and academia. Here we allow everyone to explore the platform , while our team is committed to providing support and guidance throughout the process.</Typography>
                    </Box>
                    
                        <Box sx={{ padding: '0.5em',width: { md: '55%', xs: '100%' }, boxShadow: '1px 0px 1px rgba(0,0,0,0.5)', marginX: { xs: '0.5em', md: '0' }, backgroundColor: '#FFF', borderRadius: '20px' }} >
                            <Typography p={1} variant='h4' sx={{
                                fontWeight: 600,
                                fontSize: '20px',
                                lineHeight: '32px',
                                color: '#21497F'
                            }} > Get Started as </Typography>
                        <Box px={3} py={1} sx={{ display: 'flex', gap: '1em' }} >
                        <Typography
                        onClick={()=> {
                            setSelected('Student')
                            setCompanyName('')
                        
                        }} sx={{
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '30px',
                            cursor: 'pointer',
                            color: selected === 'Student' ? '#21497F': '#9F9F9F',

                            '&:hover': {
                                transform: 'scale(1.05)'
                            }
                        }}> Student </Typography>
                        <Typography onClick={()=> {
                            setSelected('Industry')
                            setName('')
                        
                        }}  sx={{
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '30px',
                            cursor: 'pointer',
                            color: selected === 'Industry' ? '#21497F' : '#9F9F9F',
                            '&:hover': {
                                transform: 'scale(1.05)'
                            }
                        }}> Industry </Typography>
                    </Box>

                            <Grid container >
                                <Grid item xs={12} sm={6} >
                                   {selected === 'Student' ? <Box mx={2} >
                                        <TextField onChange={(e) => setName(e.target.value)} sx={{ marginX: '0.5em', marginY: '0.5em', width: '100%', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)' }} variant='outlined' label='Full name'  > </TextField>
                                        <TextField onChange={(e) => setEmail(e.target.value)} sx={{ marginX: '0.5em', marginY: '0.5em', width: '100%', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)' }} variant='outlined' label='Email'  > </TextField>
                                        <TextField onChange={(e) => setPassword(e.target.value)} type='password' sx={{ marginX: '0.5em', marginY: '0.5em', width: '100%', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)' }} variant='outlined' label='Password'  > </TextField>
                                        <Button disabled={user?.email ? true : false} onClick={handleSubmit}  sx={{
                                            background: '#FFFFFF',
                                            padding: '1em',
                                            width: '100%',
                                            border: '1px solid #2F69B6',
                                            borderRadius: '15px',
                                            transition: 'all 200ms ease-out',
                                            '&:hover': {
                                                background: '#21497F',
                                                color: '#FFF',
                                            }
                                        }} >  {user?.email ? 'Logged In Already ': 'Start your Journey'} <ForwardIcon/>  </Button>
                                        <Box my={2}>
                                            <Typography sx={{
                                                fontSize: '16px',
                                                lineHeight: '32px',
                                                color: '#21497F',
                                                textAlign: 'center'
                                            }} >Already registered? <Link to='/login' style={{ background: '#21497F', color: 'white', padding: '10px', borderRadius: '10px', textDecoration: 'none', }} > Login </Link> </Typography>
                                        </Box>
                                    </Box> : null}
                                    {selected === 'Industry' ? <Box mx={2} >
                                        <TextField onChange={(e) => setCompanyName(e.target.value)} sx={{ marginX: '0.5em', marginY: '0.5em', width: '100%', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)' }} variant='outlined' label='Company name'  > </TextField>
                                        <TextField onChange={(e) => setEmail(e.target.value)}  sx={{ marginX: '0.5em', marginY: '0.5em', width: '100%', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)' }} variant='outlined' label='Email'  > </TextField>
                                        <TextField onChange={(e) => setPassword(e.target.value)}  type='password' sx={{ marginX: '0.5em', marginY: '0.5em', width: '100%', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)' }} variant='outlined' label='Password'  > </TextField>
                                        <Button disabled={user?.email ? true : false} onClick={handleSubmit}  sx={{
                                            background: '#FFFFFF',
                                            padding: '1em',
                                            width: '100%',
                                            border: '1px solid #2F69B6',
                                            borderRadius: '15px',
                                            
                                            transition: 'all 200ms ease-out',
                                            '&:hover': {
                                                background: '#21497F',
                                                color: '#FFF',
                                            }
                                        }} > {user?.email ? 'Logged In Already ': 'Start your Journey'} <ForwardIcon /> </Button>
                                        <Box my={2}>
                                            <Typography sx={{
                                                fontSize: '16px',
                                                lineHeight: '32px',
                                                color: '#21497F',
                                                textAlign: 'center'
                                            }} >Already registered? <Link to='/login' style={{ background: '#21497F', color: 'white', padding: '10px', borderRadius: '10px', textDecoration: 'none', }} > Login </Link> </Typography>
                                        </Box>
                                    </Box> : null
                                    }

                                </Grid>
                                <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }} >
                            <Typography sx={{
                                fontWeight: 600,
                                fontSize: '17px',
                                lineHeight: '30px',
                                color: '#21497F'
                            }} >or Signup using</Typography>
                            <br />
                                <Box sx={{ display: 'flex' }} >
                                <Box sx={{ marginX: '2px', width: '50px', height: '50px', borderRadius: '15px', border: '1px solid #9A9797' }}>
                                    <FacebookIcon sx={{ fontSize: '50px', color: '#3b5998', borderRadius: '100%' }} />
                                </Box>
                                <Box sx={{ marginX: '2px', width: '50px', height: '50px', borderRadius: '15px', border: '1px solid #9A9797' }}>
                                    <LinkedInIcon sx={{ fontSize: '50px', color: '#0E76A8', borderRadius: '100%' }} />
                                </Box>
                                <Box sx={{ padding: '1px',marginX: '2px', width: '50px', height: '50px', borderRadius: '15px', border: '1px solid #9A9797' }}>
                                    <img src='/google.png' alt='google' width='47px' height='47px' />
                                </Box>
                                <Box sx={{ marginX: '2px', width: '50px', height: '50px', borderRadius: '15px', border: '1px solid #9A9797' }}>
                                    <AppleIcon sx={{ fontSize: '50px', borderRadius: '100%' }} />
                                </Box>
                            </Box>
                        </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <ToastContainer/>
           
        </div>
    )
}

export default GetStarted