import React, { useContext, useEffect, useState } from 'react'
import Resume from '../components/Upload Resume/Resume'
import {Helmet} from 'react-helmet-async'
import { Link, useNavigate } from 'react-router-dom';
import { WebContext } from '../Context/ContextProvider';
import { Box, Typography } from '@mui/material';
const UploadResume = () => {
  const navigate= useNavigate();
  const [loading, setLoading] = useState(true);
  const {loggedInAs,token} = useContext(WebContext);
   useEffect(() => {
     if (!localStorage.getItem("token")) {
       navigate("/login");
     } else {
      if (token && loggedInAs) {
        if (loggedInAs === "Student") setLoading(false);
      }
     }
   }, [token,loggedInAs]);
  return (
    <>
      <Helmet>
        <title> Just one more Step </title>
        <meta
          name="description"
          content="As-Siraat helps industry find the skillful candidates for their work"
        />
      </Helmet>
      {loading ? null :  <Resume />}
      {loggedInAs === "Industry" ? <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',}} >
        <Typography> You are logged in as Industry. Please login as Student <Link to='/login'> login </Link> </Typography>
      </Box> : null }
    </>
  );
}

export default UploadResume